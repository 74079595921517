import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Menu, MenuItem, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Icon } from 'ui';

const NavLinkWithMenu = ({ label, submenu = [], linkProps = {}, menuItemStyles = {} }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = event => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  return (
    <div
      onMouseEnter={handleMenuOpen}
      onMouseLeave={handleMenuClose}
      style={{ display: 'inline-block' }}>
      <StyledNavLink {...linkProps}>
        <Typography variant='body1' display='flex' alignItems='center'>
          <span>{label}</span>
          {submenu.length > 0 && <Icon name='ChevronDown' color='grey.800' />}
        </Typography>
      </StyledNavLink>
      {submenu.length > 0 && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          MenuListProps={{ onMouseLeave: handleMenuClose }}>
          {submenu.map((item, idx) => (
            <Link key={idx} to={item.href} style={{ textDecoration: 'none' }}>
              <MenuItem
                onClick={handleMenuClose}
                sx={{
                  textTransform: 'capitalize',
                  minWidth: '8rem',
                  ...menuItemStyles,
                }}>
                {item.text}
              </MenuItem>
            </Link>
          ))}
        </Menu>
      )}
    </div>
  );
};

export default NavLinkWithMenu;

const StyledNavLink = styled(Link)(({ theme }) => ({
  position: 'relative',
  color: theme.palette.grey[800],
  textDecoration: 'none',
  '&::before': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '2px',
    backgroundColor: theme.palette.primary.main,
    bottom: '-.4rem',
    transform: 'scaleX(0)',
    transition: 'all .4s',
    transformOrigin: 'left',
    willChange: 'transform',
  },
  '&:hover::before': {
    transform: 'scaleX(1)',
  },
}));
