import React, { useState } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import {
  AppBar,
  Stack,
  styled,
  Typography,
  IconButton,
  Box,
  List,
  Drawer,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link as MUILink,
} from '@mui/material';
import { useLocation } from '@reach/router';
import { Button, Icon } from 'ui';
import NavLinkWithMenu from './navigation-link-with-menu'; // Import the new component
import logoImg from 'images/logo.png';

import { navigationLinks } from 'utils/data'; // Assumes navigationLinks structure matches your original one

const Navigation = () => {
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const { pathname } = useLocation();

  const {
    allContentfulTickerTape: { nodes },
  } = useStaticQuery(graphql`
    query {
      allContentfulTickerTape {
        nodes {
          id
          link
          text
        }
      }
    }
  `);

  const toggleMobileNav = visibility => () => setOpenMobileNav(visibility);
  const tickerTape = nodes[0];

  return (
    <>
      <NavBar position='fixed'>
        <div className='nav-bar-wrapper'>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Link to='/'>
              <Logo src={logoImg} alt='LOGO' />
            </Link>

            {/* Desktop Navigation Links */}
            <NavLinksContainer gap={3} direction='row'>
              {navigationLinks.map((navLink, idx) => (
                <NavLinkWithMenu
                  key={idx}
                  label={navLink.text}
                  submenu={navLink.submenu || []}
                  linkProps={{
                    to: navLink.href,
                    partiallyActive: navLink.href.includes('blog'),
                    target: navLink.target || '_self',
                    activeClassName: 'nav-link-active',
                    rel: navLink.rel,
                  }}
                />
              ))}
            </NavLinksContainer>

            {/* Join Us Button */}
            <HighLightedButton
              component={Link}
              to='/contact'
              variant='primary'
              endIcon={<Icon name='ExternalLink' />}>
              join us
            </HighLightedButton>

            {/* Mobile Hamburger Menu */}
            <Hamburger onClick={toggleMobileNav(true)}>
              <Icon name='Menu' color='primary.main' />
            </Hamburger>
          </Stack>
        </div>

        {/* Ticker Tape */}
        {pathname === '/' && tickerTape && (
          <TickerTape speed={100}>
            <Typography variant='subtitle2' textAlign='center' fontWeight={600}>
              {tickerTape.text} For the latest updates,{' '}
              <MUILink
                href={tickerTape.link}
                rel='noindex nofollow'
                target='_blank'
                sx={{ color: 'white' }}>
                Click Here
              </MUILink>
            </Typography>
          </TickerTape>
        )}
      </NavBar>

      {/* Mobile Drawer */}
      <Drawer open={openMobileNav} anchor='right' onClose={toggleMobileNav(false)}>
        <MobileNav>
          <MobileNavCloserContainer>
            <IconButton onClick={toggleMobileNav(false)}>
              <Icon name='X' color='primary.main' />
            </IconButton>
          </MobileNavCloserContainer>

          {/* Mobile Navigation Links */}
          <List>
            {navigationLinks.map((navLink, idx) => (
              <div key={idx}>
                {navLink.submenu ? (
                  <Accordion sx={{ boxShadow: 'none' }} disableGutters>
                    <AccordionSummary
                      sx={{
                        p: 0,
                        pr: 1.5,
                        height: '10px',
                      }}
                      expandIcon={<Icon name='ChevronDown' color='grey.800' />}>
                      <MobileNavItem>{navLink.text}</MobileNavItem>
                    </AccordionSummary>
                    <AccordionDetails>
                      {navLink.submenu.map((subMenuItem, idx) => (
                        <MobileNavItem key={idx}>
                          <Link
                            style={{ width: '100%' }}
                            to={subMenuItem.href}
                            activeClassName='nav-link-active'
                            onClick={toggleMobileNav(false)}>
                            {subMenuItem.text}
                          </Link>
                        </MobileNavItem>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <MobileNavItem>
                    <Link
                      to={navLink.href}
                      style={{ width: '100%' }}
                      target={navLink.target || '_self'}
                      activeClassName='nav-link-active'
                      onClick={toggleMobileNav(false)}>
                      {navLink.text}
                    </Link>
                  </MobileNavItem>
                )}

                <Divider />
              </div>
            ))}
          </List>
        </MobileNav>
      </Drawer>
    </>
  );
};

export default Navigation;

/* Styled Components */
const NavBar = styled(AppBar)(({ theme }) => ({
  textTransform: 'capitalize',
  backgroundColor: 'rgba(255, 255, 255, 0.75)',
  backdropFilter: 'blur(16px) saturate(180%)',
  boxShadow: '0 1.5rem 3rem -.75rem rgba(0, 0, 0, .25)',

  '& .nav-bar-wrapper': {
    padding: `${theme.spacing(2, 5)} !important`,

    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(2, 3)} !important`,
    },
  },
}));

const Logo = styled('img')({
  height: '3rem',
  width: '10rem',
  objectFit: 'contain',
});

const NavLinksContainer = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const HighLightedButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const Hamburger = styled(IconButton)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}));

const MobileNav = styled(Box)(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  overflow: 'auto',
}));

const MobileNavCloserContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2, 2, 0),
  backgroundColor: theme.palette.common.white,
  zIndex: 99999,
  position: 'sticky',
  top: 0,
  alignItems: 'flex-end',
}));

const MobileNavItem = styled(Box)({
  fontSize: '1.5rem',
  textTransform: 'capitalize',
});

const TickerTape = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));
