import {
  careerBuildingSvg,
  measuringPerformanceSvg,
  mentorStudentSvg,
  motivationalVideoSvg,
  feature1Svg,
  feature2Svg,
  feature3Svg,
  feature4Svg,
  feature5Svg,
  feature6Svg,
  bulbOffSvg,
  bulbOnSvg,
} from 'components/svg';
import feature7Svg from 'images/feature7.png';
import purviImg from 'images/purvi.jpg';
import majmudarImg from 'images/majmudar.png';

import classTopperImg from 'images/result-cats/class-topper.png';
import schoolTopperImg from 'images/result-cats/school-topper.png';
import subjectTopperImg from 'images/result-cats/subject-topper.png';
import highAchieverImg from 'images/result-cats/high-achiever.png';

export const resultCategoryImages = {
  'school topper': schoolTopperImg,
  'class topper': classTopperImg,
  'subject topper': subjectTopperImg,
  'high achiever': highAchieverImg,
};

export const courses = [
  '11',
  '12',
  'Commerce',
  'Humanities',
  'CUET',
  'SAT',
  'IELTS',
  'MOAET',
  'M3',
];

export const navigationLinks = [
  {
    text: 'home',
    href: '/',
  },
  {
    text: 'about',
    href: '/about',
  },
  {
    text: 'courses',
    href: '/courses',
    submenu: [
      {
        text: 'all courses',
        href: '/courses',
      },
      ...courses.slice(2).map(course => ({
        text: course,
        href: `/courses/${course.toLowerCase()}`,
      })),
    ],
  },
  {
    text: 'blog',
    href: '/blog',
  },
  {
    text: 'gallery',
    href: '/gallery',
  },
  {
    text: 'contact',
    href: '/contact',
  },
  {
    text: 'login',
    href: 'https://app.iptutorials.com/student/enroll',
    rel: 'noindex nofollow',
    submenu: [
      {
        text: 'student login',
        href: 'https://app.iptutorials.com/student/login',
        rel: 'noindex nofollow',
        target: '_blank',
      },
      {
        text: 'parent login',
        href: 'https://app.iptutorials.com/parent/login',
        rel: 'noindex nofollow',
        target: '_blank',
      },
      {
        text: 'teacher login',
        href: 'https://app.iptutorials.com/teacher/login',
        rel: 'noindex nofollow',
        target: '_blank',
      },
      {
        text: 'CRM login',
        href: 'https://app.iptutorials.com/crm/login',
        rel: 'noindex nofollow',
        target: '_blank',
      },
    ],
  },
];

export const featureTiles = [
  {
    icon: careerBuildingSvg,
    text: 'career building',
  },
  {
    icon: measuringPerformanceSvg,
    text: 'measuring performance',
  },
  {
    icon: motivationalVideoSvg,
    text: 'motivational videos',
  },
  {
    icon: mentorStudentSvg,
    text: 'mentor students',
  },
];

export const subjects = {
  commerce: [
    {
      id: 0,
      title: 'accounts',
      description: `Financial Performance and Position of the business is the key information to run the business and decisions for growth of the business. Accounting is the art of recording, classifying, summarizing, analysing and interpreting the financial transactions and communicating the results to the users thereof.  E.g. Whether business has performed better than the previous year, by analysing the financial statements made by following accounting process.  
      In class 11 – Accounting processes for proprietary concerns are covered at this level. 
      In class 12 we learn the preparation of financial statement of partnership and Companies. Analysis of the financial statement is covered at this level. `,
    },
    {
      id: 1,
      title: 'business studies',
      description: `Business studies: Is the study of how to achieve any objective effectively and efficiently. This subject in first go seems to be theoretical subject, but has most practical real life application. E.g student of class 12 wants to achieve goal of 90%  in board exams – road map to achieve this will be given by management , same process can be followed by a business man to achieve his sales target too. 
      class 11 covers basic concepts of business and 12th includes  management theories which includes functions of management planning , organising ,staffing , directing and controlling .  `,
    },
    {
      id: 2,
      title: 'economics',
      description: `Economics Is a study of making fair choices, due to scarce resources with alternative uses.  
      This subject is relevant to students of all the streams, as it holistically helps in understanding the process of allocation of resources e.g – How national income is been calculated, How RBI is controlling the money supply, How government is controlling the Inflation, Why government budget is prepared, How the foreign exchange rate is derived?  
      There are different branches of economics like: micro, macro , statistics , I.ED , principles of economics , neoclassical economics , behavioural economics , etc. In different stages of your studies student covers these branches, At higher school they cover micro, statistics, I.e.d and macro.  `,
    },
    {
      id: 3,
      title: 'applied mathematics',
      description: `To develop an understanding of basic mathematical and statistical tools and their applications in the field of commerce (business/ finance/economics) and social sciences. To model real-world experiences/problems into mathematical expressions using numerical/algebraic/graphical representation.`,
    },
    {
      id: 4,
      title: 'entrepreneurship',
      description: `To encourage school children to opt for self-employment as a viable option for earning dignified means of living. To enable students to appreciate the dynamic changes happening in the economy. To acquaint the students about the role of Entrepreneurship in the growth and economic development of the nation. To promote Entrepreneurship as life-skills to improve quality of life, skills of creation and management of entrepreneurial pursuits.`,
    },
  ],
  humanities: [
    {
      id: 0,
      title: 'psychology',
      description: `The course deals with psychological knowledge and practices which are contextually rooted. It emphasizes the complexity of behavioral processes and discourages simplistic cause-effect thinking. This is pursued by encouraging critical reasoning, allowing students to appreciate the role of cultural factors in behavior and illustrating how biology and experiences shape behavior.`,
    },
    {
      id: 1,
      title: 'history',
      description: `The History curriculum introduces the students to a set of important historical events and processes through a focus on a series of historical issues, debates and through various sources. A discussion of these themes would allow students not only to know about the events and processes. It provides a crucial perspective for understanding and solving the current and future problems. `,
    },
    {
      id: 2,
      title: 'economics',
      description: `Economics Is a study of making fair choices, due to scarce resources with alternative uses.  
      This subject is relevant to students of all the streams, as it holistically helps in understanding the process of allocation of resources e.g – How national income is been calculated, How RBI is controlling the money supply, How government is controlling the Inflation, Why government budget is prepared, How the foreign exchange rate is derived?  
      There are different branches of economics like: micro, macro , statistics , I.ED , principles of economics , neoclassical economics , behavioural economics , etc. In different stages of your studies student covers these branches, At higher school they cover micro, statistics, I.e.d and macro.  `,
    },
    {
      id: 3,
      title: 'entrepreneurship',
      description: `To encourage school children to opt for self-employment as a viable option for earning dignified means of living. To enable students to appreciate the dynamic changes happening in the economy. To acquaint the students about the role of Entrepreneurship in the growth and economic development of the nation. To promote Entrepreneurship as life-skills to improve quality of life, skills of creation and management of entrepreneurial pursuits.`,
    },
    {
      id: 4,
      title: 'applied mathematics',
      description: `To develop an understanding of basic mathematical and statistical tools and their applications in the field of commerce (business/ finance/economics) and social sciences. To model real-world experiences/problems into mathematical expressions using numerical/algebraic/graphical representation.`,
    },
  ],
};

export const aboutSlides = [
  {
    svg: bulbOffSvg,
    title: 'vision',
    text: `is to provide all academic solution under one roof, build relationship on trust and cater best services pan globe.`,
  },
  {
    svg: bulbOnSvg,
    title: 'mission',
    text: `We aim to be premium provider of high - quality coaching services that foster critical thinking, creative & lifelong learning.`,
  },
];

export const aboutSteps = [
  'began in 2011',
  'built 2 centers',
  '10,000+ students',
  '5,000+ students counseled',
  'built 2 integrated schools',
  'industry trending',
  'going global',
];

export const addressals = {
  parent: [
    {
      icon: feature1Svg,
      text: "We not only focus on your child's academic success but also implement corrective measures during their training.",
    },
    {
      icon: feature2Svg,
      text: 'We assume complete responsibility for your child, including monitoring attendance, administering essential tests, and maintaining regular communication with you regarding their progress.',
    },
    {
      icon: feature3Svg,
      text: 'Being affiliated with renowned schools provides our students with an advantage, enabling them to surpass their peers.',
    },
  ],

  student: [
    {
      icon: feature4Svg,
      text: "IP Tutorials' approach is to cover subjects' concepts and fundamentals, ensuring a complete understanding of the topics while making the learning process effortless.",
    },
    {
      icon: feature5Svg,
      text: 'We advocate for a holistic teaching approach that can assist you in enhancing your skill set.',
    },
    {
      icon: feature6Svg,
      text: 'With our proven track record of success and our unwavering dedication to student success, providing you with a competitive edge.',
    },
  ],
};

export const integratedSchool = {
  icon: feature7Svg,
  points: [
    'One test',
    'More time',
    'One school',
    'Better results',
    'No Confusion',
    'No extra transportation cost',
    'More time for Entrance exam',
    'Relaxed and happier children',
    'Set accountability at one place',
    'Highly qualified and passionate Teachers',
  ],
  text: 'Commerce and humanities integrated school all under one roof',
};

export const coreTeam = [
  {
    image: purviImg,
    name: 'purvi shah saggu',
    title: 'Woman Mentor of the year awardee 2024',
    description: `Teacher by Heart and Educationalist by Profession. Her Ability to teach & Mentor is backed by extensive hard work and competence, She believes in the power of action, she recognizes the importance of words and their ability to influence change.`,
    qualification: 'M.A (Eco) B.ed',
    experience: '20+ years',
  },
  {
    image: majmudarImg,
    name: 'ronak majmudar',
    description: `Love for numbers & enthusiast in whatever he does makes him a popular & student loved Teacher. He is a highly skilled CA who possesses the ability to simplify the learning of accounts. With vast knowledge and expertise in the subject, he is recognized as a stalwart faculty in the field of accounts, widely known for his exceptional teaching abilities.`,
    qualification: 'Chartered Accountant',
    experience: '10+ years',
  },
];

export const quizQuestions = [
  {
    id: 'question1',
    question: 'Identify which of the following statement is correct:',
    options: [
      'British colonial rule made India net importer of raw material.',
      'The real motive behind infrastructure development by Britishers was to serve the people of India.',
      'India generated large export surplus during the British rule.',
      'Indian handicraft industry enjoyed world wide reputation under the British rule.',
    ],
    correctAnswer: 2,
  },

  {
    id: 'question2',
    question: 'Identify which of the following statement is correct:',
    options: [
      "Restrictive policies of commodity production, trade and tariff paused by the colonial government adversely affected the structure, composition and volume of India's foreign trade.",
      "Effective trade policies of commodity purchase, trade and tariff pursued by the colonial government favorably affected the structure, composition and volume of India's foreign trade.",
      "Liberal policies of commodity production. Trade and tariff pursued by the colonial government adversely affected the structure, composition and volume of the India's foreign trade.",
      "Restrictive policies of commodity production, trade and tariff pursued by the colonial government favorably affected the structure, composition and volume of India's foreign trade",
    ],
    correctAnswer: 0,
  },
  {
    id: 'question3',
    question: 'India entered the _____ stage of demographic transition after the year of 1921.',
    options: ['Fourth', 'Second', 'Third', 'First'],
    correctAnswer: 1,
  },
  {
    id: 'question4',
    question: 'Occupational structure refers to _____.',
    options: [
      'Size of labor force in a country',
      'Number of people living in a country',
      'Distribution of workforce among different sectors of an economy',
      'Nature of different occupations',
    ],
    correctAnswer: 2,
  },
  {
    id: 'question5',
    question:
      "Prior to India's independence , the stagnation in the agricultural sector was mainly due to _____.",
    options: [
      'Investment in technology',
      'Investment in agriculture facilities',
      'Advanced agricultural facilities',
      'Land settlement system',
    ],
    correctAnswer: 3,
  },
];
